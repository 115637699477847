module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://hydeparkwinterwonderland.com/"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.hydeparkwinterwonderland.com/graphql","verbose":true,"schema":{"perPage":25,"requestConcurrency":5,"timeout":90000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"type":{"MediaItem":{"localFile":{"requestConcurrency":5,"maxFileSizeBytes":40485760,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hyde Park Winter Wonderland","short_name":"Winter Wonderland","start_url":"/","background_color":"#0C168E","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon-512x512.png","prefer_related_applications":true,"related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=com.hpww.winterwonderland&hl=en_GB&gl=US","id":"com.hpww.winterwonderland"},{"platform":"itunes","url":"https://apps.apple.com/us/app/hyde-park-winter-wonderland/id1594351942"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e7b312849d8d63607eeebd99c722489"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MBZS4M","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
